import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Center, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import ProductCard from "../../components/general/product-card/ProductCard";
import SubCategoryContainer from "../../components/sub-category-container/SubCategoryContainer";
import CategoriesContext from "../../context/categories/context";
import ProductsContext from "../../context/products/context";
import { useCategoryParamsId } from "../../utils/hooks/useParamsId";
import CartNotificaiton from "../shopping-cart/CartNotification";
import {
  Action,
  removeLoadingElements,
} from "../../store/actions/orderActions";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../store/reducers/rootReducer";
import { useLocation } from "react-router-dom";
import BreadCrumbComponent from "../../components/bread-crumb/BreadCrumbComponent";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "../../hooks/useInfifniteScroll";

interface StateProps {
  cartSpinner: string | null;
  showNotification: boolean;
}

interface DispatchProps {
  onRemoveLoadingElements: () => void;
}

interface IProps {}
const Category: React.FC<StateProps & DispatchProps & IProps> = (props) => {
  const categoryId = useCategoryParamsId();
  const didMountRef = useRef(false);
  const { list: categoryList } = useContext(CategoriesContext);
  const {
    products: productList,
    currentPage: currentPage,
    totalPages: totalPages,
    actions: productActions,
    loading,
  } = useContext(ProductsContext);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { t } = useTranslation();

  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [product, setProduct] = useState<any>();

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const handleChooseProduct = (product: any) => {
    setProduct(product);
  };

  const handleChangePage = () => {
    if (productList && loading.length == 0)
      if (currentPage > 0 && currentPage < totalPages)
        productActions.getProducts({
          category_id: categoryId,
          page: currentPage + 1,
        });
  };

  const [lastElementRef, isFetchingMore] = useInfiniteScroll(handleChangePage);

  useEffect(() => {
    if (!didMountRef.current) {
      productActions.getProducts({ category_id: categoryId, page: 1 });
      didMountRef.current = true;
    }
  }, [categoryId, productActions]);

  useEffect(() => {
    if (props.showNotification) {
      props.onRemoveLoadingElements();
      setShowNotification(true);

      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }
  }, [props.showNotification]);

  const renderProducts = () => {
    // if (!productList || loading.includes("products")) {
    //   return (
    //     <Center height="100vh">
    //       <Spinner size="xl" />
    //     </Center>
    //   );
    // }
    if (productList && productList.length > 0)
      return (
        <Grid
          templateColumns={{
            base: "1fr",
            md: "1fr 1fr",
            lg: `1fr 1fr 1fr`,
          }}
          width={"100%"}
          gap={{ base: "25px", md: "25px", lg: 3 }} // Adjust gap between grid items as needed
          mt={{ base: "1.5em", md: "1.5em", lg: "3em" }}
          justifyItems={"center"}
        >
          {" "}
          {productList.map((product: any, index: number) => {
            if (index === productList.length - 1) {
              return (
                <GridItem
                  width={{ base: "90%", md: "100%", lg: "100%" }}
                  key={product.id}
                  ref={lastElementRef}
                >
                  <ProductCard
                    key={product.id}
                    id={product.id}
                    url={product.thumbnail}
                    name={product.name}
                    discountPrice={product.discount_price}
                    price={product.price}
                    product={product}
                    selectProduct={() =>
                      productActions.getProductById(product.id)
                    }
                    handleChooseProduct={handleChooseProduct}
                  />
                </GridItem>
              );
            }

            return (
              <GridItem
                width={{ base: "90%", md: "100%", lg: "100%" }}
                key={product.id}
              >
                <ProductCard
                  key={product.id}
                  id={product.id}
                  url={product.thumbnail}
                  name={product.name}
                  discountPrice={product.discount_price}
                  price={product.price}
                  product={product}
                  selectProduct={() =>
                    productActions.getProductById(product.id)
                  }
                  handleChooseProduct={handleChooseProduct}
                />
              </GridItem>
            );
          })}
          {showNotification ? (
            <CartNotificaiton
              message="Item added to cart!"
              product={product}
              handleCloseNotification={handleCloseNotification}
            />
          ) : null}
        </Grid>
      );
    else
      return (
        <Grid templateColumns={["1fr"]} width="100%" mt="50px">
          <Center width={"100%"}>
            <img src="/images/empty/empty-products.png" />
          </Center>{" "}
          <Flex justifyContent={"center"} alignItems={"center"} mt="20px">
            <Text fontSize={"16px"} fontWeight={"bold"}>
              {t("emptyProducts")}
            </Text>
          </Flex>
        </Grid>
      );
  };
  return (
    <Box
      px={{ base: "1rem", md: "none", lg: "120px" }}
      mt={{ base: "4em", md: "4em", lg: "9.375em" }}
    >
      <BreadCrumbComponent categoryList={categoryList} />
      <SubCategoryContainer />
      {renderProducts()}
    </Box>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    cartSpinner: state.orders.spinner,
    showNotification: state.orders.showNotification,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onRemoveLoadingElements: () => dispatch(removeLoadingElements()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Category);
